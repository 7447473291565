import { createRouter, createWebHistory } from "vue-router";
import IntroductionView from "../views/dashboard/introduce/index.vue";

const routes = [
  {
    path: "/",
    name: "introduction-view",
    component: IntroductionView,
  },
  // Package
  {
    path: "/package",
    name: "package-view",

    component: () =>
      import(
        /* webpackChunkName: "package-view" */ "../views/dashboard/packages/index.vue"
      ),
  },
  // Features
  {
    path: "/features",
    name: "features-view",

    component: () =>
      import(
        /* webpackChunkName: "features-view" */ "../views/dashboard/features/index.vue"
      ),
  },

  // About
  {
    path: "/about",
    name: "about-view",

    component: () =>
      import(
        /* webpackChunkName: "about-view" */ "../views/dashboard/about/index.vue"
      ),
  },

  {
    path: "/policy",
    name: "policy-view",

    component: () =>
      import(
        /* webpackChunkName: "policy-view" */ "../views/dashboard/policy/index.vue"
      ),
  },
  {
    path: "/term-of-service",
    name: "term-of-service-view",

    component: () =>
      import(
        /* webpackChunkName: "term-of-service-view" */ "../views/dashboard/term-of-service/index.vue"
      ),
  },
  // Profile
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
