/* eslint-disable prettier/prettier */
import { http_mongo } from "../../configs/http-mongo";

const state = {
  nameSchools: [],
  statusImageVerify: true,
  listLifeStyleCommons: {}, // Lấy danh sách chung đơn lẻ
  listInformationBasic: {}, // Lấy danh sách các thông tin cơ bản
  listLifeStyleStatic: {}, // Lấy danh sách phong cách sống
  listStaticRegister: {}, // Danh sách các option chọn khi đăng ký
  listPromptRegister: {}, // Danh sách Prompt
  listGenderChose: [],

  listInterestFilter: [],

  totalLikeYou: 0,

  listLanguages: [
    {
      code: "item_1",
      value: "Afrikaans",
    },
    {
      code: "item_3",
      value: "Amharic",
    },
    {
      code: "item_2",
      value: "Albanian",
    },
    {
      code: "item_4",
      value: "Arabic",
    },
    {
      code: "item_6",
      value: "Assamese",
    },
    {
      code: "item_5",
      value: "Armenian",
    },
    {
      code: "item_7",
      value: "Aymara",
    },
    {
      code: "item_8",
      value: "Azerbaijani",
    },
    {
      code: "item_9",
      value: "Bambara",
    },
    {
      code: "item_11",
      value: "Belarusian",
    },
    {
      code: "item_10",
      value: "Basque",
    },
    {
      code: "item_13",
      value: "Bhojpuri",
    },
    {
      code: "item_12",
      value: "Bengali",
    },
    {
      code: "item_15",
      value: "Bulgarian",
    },
    {
      code: "item_14",
      value: "Bosnian",
    },
    {
      code: "item_16",
      value: "Burmese",
    },
    {
      code: "item_17",
      value: "Cantonese",
    },
    {
      code: "item_18",
      value: "Catalan",
    },
    {
      code: "item_19",
      value: "Cebuano",
    },
    {
      code: "item_21",
      value: "Corsican",
    },
    {
      code: "item_22",
      value: "Croatian",
    },
    {
      code: "item_20",
      value: "Chichewa",
    },
    {
      code: "item_23",
      value: "Czech",
    },
    {
      code: "item_24",
      value: "Danish",
    },
    {
      code: "item_25",
      value: "Dhivehi",
    },
    {
      code: "item_26",
      value: "Dogri",
    },
    {
      code: "item_27",
      value: "Dutch",
    },
    {
      code: "item_28",
      value: "English",
    },
    {
      code: "item_29",
      value: "Esperanto",
    },
    {
      code: "item_30",
      value: "Estonian",
    },
    {
      code: "item_32",
      value: "Filipino",
    },
    {
      code: "item_34",
      value: "French",
    },
    {
      code: "item_33",
      value: "Finnish",
    },
    {
      code: "item_31",
      value: "Ewe",
    },
    {
      code: "item_35",
      value: "Frisian",
    },
    {
      code: "item_36",
      value: "Galician",
    },
    {
      code: "item_38",
      value: "German",
    },
    {
      code: "item_37",
      value: "Georgian",
    },
    {
      code: "item_39",
      value: "Greek",
    },
    {
      code: "item_40",
      value: "Guarani",
    },
    {
      code: "item_41",
      value: "Gujarati",
    },
    {
      code: "item_43",
      value: "Hausa",
    },
    {
      code: "item_42",
      value: "Haitian Creole",
    },
    {
      code: "item_44",
      value: "Hawaiian",
    },
    {
      code: "item_46",
      value: "Hindi",
    },
    {
      code: "item_45",
      value: "Hebrew",
    },
    {
      code: "item_48",
      value: "Hungarian",
    },
    {
      code: "item_47",
      value: "Hmong",
    },
    {
      code: "item_49",
      value: "Icelandic",
    },
    {
      code: "item_50",
      value: "Igbo",
    },
    {
      code: "item_53",
      value: "Irish",
    },
    {
      code: "item_51",
      value: "Ilocano",
    },
    {
      code: "item_52",
      value: "Indonesian",
    },
    {
      code: "item_54",
      value: "Italian",
    },
    {
      code: "item_55",
      value: "Japanese",
    },
    {
      code: "item_56",
      value: "Javanese",
    },
    {
      code: "item_57",
      value: "Kannada",
    },
    {
      code: "item_58",
      value: "Kazakh",
    },
    {
      code: "item_59",
      value: "Khmer",
    },
    {
      code: "item_60",
      value: "Kinyarwanda",
    },
    {
      code: "item_62",
      value: "Korean",
    },
    {
      code: "item_61",
      value: "Konkani",
    },
    {
      code: "item_63",
      value: "Krio",
    },
    {
      code: "item_64",
      value: "Kurdish",
    },
    {
      code: "item_65",
      value: "Kyrgyz",
    },
    {
      code: "item_66",
      value: "Lao",
    },
    {
      code: "item_67",
      value: "Latin",
    },
    {
      code: "item_69",
      value: "Lingala",
    },
    {
      code: "item_68",
      value: "Latvian",
    },
    {
      code: "item_70",
      value: "Lithuanian",
    },
    {
      code: "item_72",
      value: "Luxembourgish",
    },
    {
      code: "item_71",
      value: "Luganda",
    },
    {
      code: "item_73",
      value: "Macedonian",
    },
    {
      code: "item_74",
      value: "Maithili",
    },
    {
      code: "item_75",
      value: "Malagasy",
    },
    {
      code: "item_76",
      value: "Malay",
    },
    {
      code: "item_77",
      value: "Malayalam",
    },
    {
      code: "item_78",
      value: "Maltese",
    },
    {
      code: "item_79",
      value: "Mandarin Chinese",
    },
    {
      code: "item_80",
      value: "Manipuri",
    },
    {
      code: "item_82",
      value: "Marathi",
    },
    {
      code: "item_81",
      value: "Maori",
    },
    {
      code: "item_83",
      value: "Mizo",
    },
    {
      code: "item_84",
      value: "Mongolian",
    },
    {
      code: "item_85",
      value: "Nepali",
    },
    {
      code: "item_86",
      value: "Norwegian",
    },
    {
      code: "item_87",
      value: "Odia",
    },
    {
      code: "item_88",
      value: "Oromo",
    },
    {
      code: "item_89",
      value: "Pashto",
    },
    {
      code: "item_90",
      value: "Persian",
    },
    {
      code: "item_91",
      value: "Polish",
    },
    {
      code: "item_93",
      value: "Punjabi",
    },
    {
      code: "item_92",
      value: "Portuguese",
    },
    {
      code: "item_94",
      value: "Quechua",
    },
    {
      code: "item_95",
      value: "Romanian",
    },
    {
      code: "item_96",
      value: "Russian",
    },
    {
      code: "item_97",
      value: "Samoan",
    },
    {
      code: "item_98",
      value: "Sanskrit",
    },
    {
      code: "item_99",
      value: "Scots Gaelic",
    },
    {
      code: "item_103",
      value: "Shona",
    },
    {
      code: "item_102",
      value: "Sesotho",
    },
    {
      code: "item_100",
      value: "Sepedi",
    },
    {
      code: "item_101",
      value: "Serbian",
    },
    {
      code: "item_104",
      value: "Sindhi",
    },
    {
      code: "item_105",
      value: "Sinhala",
    },
    {
      code: "item_107",
      value: "Slovenian",
    },
    {
      code: "item_106",
      value: "Slovak",
    },
    {
      code: "item_108",
      value: "Somali",
    },
    {
      code: "item_109",
      value: "Spanish",
    },
    {
      code: "item_112",
      value: "Swedish",
    },
    {
      code: "item_110",
      value: "Sundanese",
    },
    {
      code: "item_111",
      value: "Swahili",
    },
    {
      code: "item_113",
      value: "Tajik",
    },
    {
      code: "item_115",
      value: "Tatar",
    },
    {
      code: "item_117",
      value: "Thai",
    },
    {
      code: "item_116",
      value: "Telugu",
    },
    {
      code: "item_114",
      value: "Tamil",
    },
    {
      code: "item_118",
      value: "Tigrinya",
    },
    {
      code: "item_119",
      value: "Tsonga",
    },
    {
      code: "item_123",
      value: "Ukrainian",
    },
    {
      code: "item_122",
      value: "Twi",
    },
    {
      code: "item_125",
      value: "Uyghur",
    },
    {
      code: "item_120",
      value: "Turkish",
    },
    {
      code: "item_121",
      value: "Turkmen",
    },
    {
      code: "item_124",
      value: "Urdu",
    },
    {
      code: "item_126",
      value: "Uzbek",
    },
    {
      code: "item_127",
      value: "Vietnamese",
    },
    {
      code: "item_128",
      value: "Welsh",
    },
    {
      code: "item_129",
      value: "Xhosa",
    },
    {
      code: "item_132",
      value: "Zulu",
    },
    {
      code: "item_131",
      value: "Yoruba",
    },
    {
      code: "item_130",
      value: "Yiddish",
    },
  ],
};
function getDefaultStore() {
  return {
    listStaticRegister: {},
    listLifeStyleCommons: {},
    listInformationBasic: {},
    listLifeStyleStatic: {},
    listGenderChose: [],
    listInterestFilter: [],
  };
}
const getters = {};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultStore());
  },
  /**
   * Xét giá trị danh sách riêng lẻ: datingPurposes,languages,interests,schools,jobTitles,sexuals,genders,genderFilters...
   * @param {*} state
   * @param {*} data
   */
  setListLifeStyleCommons(state, data) {
    // Danh sách các phần tử common
    state.listLifeStyleCommons = data;
    // Lấy tên trường và key
    for (let index = 0; index < data.schools.length; index++) {
      const element = data.schools[index];
      state.nameSchools.push({
        value: element,
        id: element,
      });
    }
  },

  /**
   * Xét giá trị danh sách thông tin cơ bản
   * @param {*} state
   * @param {*} data
   */
  setListInformationBasic(state, data) {
    state.listInformationBasic = data;
  },

  /**
   * Xét giá trị danh sách phong cách sống: Life style
   * @param {*} state
   * @param {*} data
   */
  setListLifeStyleStatic(state, data) {
    state.listLifeStyleStatic = data;
  },

  /**
   * Xét giá trị option khi đăng ký
   * @param {*} state
   * @param {*} data
   */
  setListStaticRegister(state, data) {
    state.listStaticRegister = data;
  },
  setListPromptRegister(state, data) {
    state.listPromptRegister = data;
  },

  setGenderChose(state, data) {
    const findId = state.listGenderChose.findIndex((x) => x.code === data.code);

    if (findId !== -1) {
      state.listGenderChose[findId].isActive = data.isActive;
    } else {
      const findId = state.listGenderChose.findIndex(
        (x) => x.isActive === true
      );
      if (findId !== -1) {
        state.listGenderChose.splice(findId, 1);
        state.listGenderChose.push(data);
      } else {
        state.listGenderChose.splice(1, 1);
        state.listGenderChose.push(data);
      }
    }
  },

  setGenderActive(state, data) {
    const findId = state.listGenderChose.findIndex((x) => x.code === data.code);

    state.listGenderChose[findId].isActive = true;

    // for (let index = 0; index < state.listGenderChose.length; index++) {
    //   const element = state.listGenderChose[index];

    // }
  },

  setListOtherUserLikeYou(state, data) {
    state.totalLikeYou = data.total;
  },
};

const actions = {
  /**
   * List common: datingPurposes,languages,interests,schools,jobTitles,sexuals,genders,genderFilters...
   * @param {*} param0
   */
  async getListLifeStyleCommons({ commit }) {
    http_mongo
      .get(`api/v1/statics/commons`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("setListLifeStyleCommons", response.data.data);
        }
      })
      .catch((error) => { });
  },

  /**
   * List basic information: zodiacs, educations,covidVaccines,personalities,communicationStyles,loveStyles
   * @param {*} param0
   * @returns
   */
  async getListInformationBasic({ commit }) {
    http_mongo
      .get(`api/v1/statics/basics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("setListInformationBasic", response.data.data);
        }
      })
      .catch((error) => { });
  },

  /**
   * List life style:pets, drinkings,smokings,workout,foodPreferences,socials,sleepingStyles
   * @param {*} param0
   * @returns
   */
  async getListLifeStyleStatic({ commit }) {
    http_mongo
      .get(`api/v1/statics/life-styles`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("setListLifeStyleStatic", response.data.data);
        }
      })
      .catch((error) => { });
  },

  /**
   * Lấy danh sách những ng khác đã like bạn
   * @param {*} param0
   */
  async getListOtherUserLikeYou({ commit }, data) {
    http_mongo
      .get(
        `/api/v1/list-action-you?pageSize=${data.pageSize}&currentPage=${data.currentPage}&action=${data.action}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          commit("setListOtherUserLikeYou", response.data.data);
        }
      })
      .catch((error) => { });
  },

  async getListStaticRegister({ commit }) {
    http_mongo
      .get(`/api/v1/guest/statics`, {
        headers: {
          "Accept-Language": `en`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("setListStaticRegister", response.data.data);
        }
      })
      .catch((error) => { });
  },

  async getListPromptRegister({ commit }) {
    http_mongo
      .get(`/api/v1/guest/prompts`, {
        headers: {
          "Accept-Language": `en`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("setListPromptRegister", response.data.data);
        }
      })
      .catch((error) => { });
  },

  /**
   * Verify Image
   * @param {*} param0
   * @param {*} param1
   */
  // async verifyImageRegister({ commit }, formData) {
  //   await http_ai
  //     .post(`nhananh`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       commit("setVerifyImageRegister", response.data.data);
  //     })
  //     .catch((error) => {
  //       commit("setVerifyImageRegister", true);
  //     });
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
