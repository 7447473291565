<template>
  <div class="w-full h-full">
    <main-menu></main-menu>
  </div>
</template>

<script>
import mainMenu from "@/components/dashboard/mainMenu.vue";
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  components: { mainMenu },
  name: "IntroductionView",

  methods: {
    ...mapActions(["loginApp"]),
  },
});
</script>

<style scoped lang="scss"></style>
