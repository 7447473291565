<template>
  <section class="section bg-footer text-white">
    <div class="container mx-auto w-full p-10 border-top">
      <div class="row">
        <div class="grid-cols-10 underline-offset-1 w-full">
          <h2 class="text-2xl font-semibold">GET THE APP</h2>
          <div class="flex items-center justify-center gap-10 p-5">
            <img
              src="../../assets/icon_svg/ic_download_app.svg"
              width="140"
              height="37"
              alt=""
              class="cursor-pointer"
            />
            <img
              src="../../assets/icon_svg/ic_download_google_play.svg"
              width="140"
              height="37"
              class="cursor-pointer"
            />
          </div>
          <div class="flex justify-center w-full gap-3 p-5 cursor-pointer">
            <img src="../../assets/icon_svg/mdi_instagram.svg" width="30" />
            <img
              src="../../assets/icon_svg/ic_baseline-tiktok.svg"
              width="30"
            />
            <img
              src="../../assets/icon_svg/icon-park-solid_youtobe.svg"
              width="30"
            />
            <img src="../../assets/icon_svg/mdi_twitter.svg" width="30" />
            <img src="../../assets/icon_svg/ri_facebook-fill.svg" width="30" />
          </div>

          <div class="w-full flex justify-between text-describe pt-2 pb-2">
            <div>
              FAQ / Safety tips / Terms / Cookie Policy / Privacy settings
            </div>
            <div>
              © 2024 MELOHA Group’s Copyright Agent. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "footer-page",

  data() {
    return {};
  },

  methods: {},
});
</script>

<style lang="scss" scoped>
.text-describe {
  color: #979798;
  font-size: 16px;
}

.border-top {
  border-top: 1px solid #bdbacd30;
}
</style>
