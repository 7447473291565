const funValidation = {
  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  /**
   * Hàm convert từ dateTime to age
   * @param {*} dob
   * @returns
   */
  calculateAge(dob) {
    const dateNew = new Date(dob);
    var diff_ms = Date.now() - dateNew.getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  },

  /**
   * Chuyển đôi sang mi
   * @param {*} value
   * @returns
   */
  locationKmToMi(value) {
    const mi = value / 1.609344;
    return Math.round(mi);
  },

  /**
   * Kiểm tra xem đang sử dụng trên thiết bị nào?
   * 1: PC
   * 2: Phone
   * @returns
   */
  checkDeviceType() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("windows") >= 0 && userAgent.indexOf("mobile") < 0) {
      return 1;
    } else if (
      userAgent.indexOf("mac") >= 0 ||
      userAgent.indexOf("ipad") >= 0 ||
      (userAgent.indexOf("windows") >= 0 && userAgent.indexOf("phone") < 0) ||
      (userAgent.indexOf("linux") >= 0 && userAgent.indexOf("android") < 0)
    ) {
      return 2;
    } else {
      return "Unknown";
    }
  },

  /**
   * Xử lý viết hoa chữ cái đầu
   * @param {*} str
   * @returns
   */
  capitalizeFirstLetter(str) {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  // Convert cm => ft/in
  cmToFeetAndInches(cm) {
    // 1 cm = 0.0328084 feet
    var feet = Math.floor(cm * 0.0328084);
    // 1 foot = 12 inches
    var inches = Math.round((cm * 0.0328084 - feet) * 12);
    return feet + "ft " + inches + "in";
  },

  // Convert  ft/in =>cm
  feetAndInchesToCm(feet, inches) {
    // 1 foot = 30.48 cm
    var cmFromFeet = feet * 30.48;
    // 1 inch = 2.54 cm
    var cmFromInches = inches * 2.54;
    // Tổng số centimet tính từ feet và inches
    var totalCm = cmFromFeet + cmFromInches;
    return totalCm;
  },
};

export default funValidation;
