import { http_chat } from "../../configs/http-chat";

const state = {
  listChanelIds: [],
  listMessages: [],
  listFriends: [],
  messageRecode: {},
  listMessageSelected: [],
  channelIdUserReceiver: "",
};

const getters = {};

const mutations = {
  setListChanelId(state, data) {
    state.listChanelIds = data.list_data;
  },

  setNotificationSocket(state, data) {
    const channelId = data.channelId;
    const findIdData = state.listChanelIds.find(
      (x) => x.channelId === channelId
    );
    if (findIdData) {
      const idUserSelected = state.listMessageSelected.find(
        (x) => x.idChannel === channelId
      );

      if (!idUserSelected) {
        actions
          .getListMessageChanel(
            {},
            {
              chanelId: channelId,
              pageSize: 50,
              currentPage: 0,
            }
          )
          .then((data) => {
            state.listMessageSelected.push({
              idChannel: channelId,
              dataClient: data.clients,
              isShowForm: true,
              dataMessages: data.list_data,
            });
          });
      }
    }
  },

  setListMessageChanel(state, data) {
    state.listMessages = data;
  },

  setListFriends(state, data) {
    state.listFriends = data.list_data;
  },

  setMessageId(state, data) {
    state.messageRecode = data;
  },

  setListMessageSelected(state, data) {
    state.listMessageSelected.push(data);
  },

  setRemoveMessage(state, data) {
    state.listMessageSelected = state.listMessageSelected.filter(
      (x) => x.idChannel != data
    );
  },

  setAddMessage(state, data) {
    if (state.listMessageSelected.length !== 0) {
      const idUserSelected = state.listMessageSelected.find(
        (x) => x.idChannel === data.channelId
      );

      if (idUserSelected) {
        idUserSelected.dataMessages.push(data);
      } else {
        mutations.setNotificationSocket(state, data);
      }
    } else {
      mutations.setNotificationSocket(state, data);
    }

    // const idUserSend = localStorage.getItem("idUser");

    // if (data.senderId === idUserSend) {
    //   const idUserSelected = state.listMessageSelected.find(
    //     (x) => x.idChannel === data.channelId
    //   );
    //   if (idUserSelected) {
    //     idUserSelected.dataMessages.push(data);
    //   }
    // } else {
    //   mutations.setNotificationSocket(state, data);
    // }
  },

  setChannelIdUser(state, data) {
    state.channelIdUserReceiver = data.channelId;
  },
};

const actions = {
  /**
   * Lấy danh sách chanelID
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async getListChanelId({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_chat
        .get(
          `/api/v1/channels?pageSize=${data.pageSize}&currentPage=${data.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setListChanelId", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Danh sách chi tiết đoạn tin nhắn
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async getListMessageChanel({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_chat
        .get(
          `/api/v1/channels/${data.chanelId}/messages?pageSize=${data.pageSize}&currentPage=${data.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // commit("setListMessageChanel", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Lấy danh sách bạn bè
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async getListFriends({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_chat
        .get(
          `/api/v1/friends/new?pageSize=${data.pageSize}&currentPage=${data.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setListFriends", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Gửi tin nhắn
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async addMessageChanel({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_chat
        .post(
          `/api/v1/channels/${data.idMessage}/add-message`,
          data.objectMessage,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setMessageId", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Lấy channelId của User
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async getChannelIdUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_chat
        .get(`/api/v1/getChannelId?receiverId=${data.idUser}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setChannelIdUser", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Cập nhật trạng thái tin nhắn
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async updateStatusMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_chat
        .get(`/api/v1/messages/update-status`, data.objectMessage, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setUpdateStatusMessage", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
