/* eslint-disable prettier/prettier */
import { http_mongo } from "../../configs/http-mongo";

const state = {
    listRecommends: [],
    cardFeed: {},
    statusUpdate: "",
    indexValue: 0,

    modelUser: {
        oAuth2Id: "",
        fullname: "",
        email: "",
        phone: "",
        dob: "",
        profiles: {
            showCommon: {
                showSexual: false,
                showGender: false,
                showAge: false,
                showHeight: false,
                showEthnicity: false,
                showChildrenPlan: false,
                showFamilyPlan: false,
                showWork: false,
                showSchool: false,
                showEducation: false,
                showDrinking: false,
                showSmoking: false,
                showDrug: false,
                showDistance: false,
            },
            interests: [],
            orientationSexuals: [],
            languages: [],
            ethnicitys: [],
            smartPhoto: false,
            favoriteSongs: [],
            gender: "",
            address: "",
            school: "",
            height: 0,
            childrenPlan: "",
            familyPlan: "",
            company: "",
            education: "",
            drinking: "",
            smoking: "",
            drug: "",
            about: "",
            zodiac: "",
            covidVaccine: "",
            personality: "",
            communicationType: "",
            loveStyle: "",
            pet: "",
            workout: "",
            dietaryPreference: "",
            socialMedia: "",
            sleepingHabit: "",
            datingPurpose: "",
            prompts: [],
            avatars: []
        },
        settings: {
            global: {
                isEnabled: false,
                languages: []
            },
            distancePreference: {
                range: 10,
                unit: "km",
                onlyShowInThis: false
            },
            agePreference: {
                min: 15,
                max: 30,
                onlyShowInThis: false
            },
            notiSeenEmail: {
                newMatchs: false,
                incomingMessage: false,
                promotions: false
            },
            notiSeenApp: {
                newMatchs: false,
                incomingMessage: false,
                promotions: false,
                superLike: false
            },
            genderFilter: "all",
            autoPlayVideo: "always",
            showTopPick: true,
            showOnlineStatus: true,
            showActiveStatus: true,
            incognitoMode: true
        },
        verifyStatus: false,
        activeStatus: false,
        location: {
            lat: "",
            long: ""
        },
        coins: 0,
        explore: {
            verified: false,
            topics: []
        },
        plusCtrl: {
            whoYouSee: "default",
            whoSeeYou: "everyone"
        },
        numberLike: 0,
        numberSuperLike: 0,
        numberBooster: 0
    },

    listPromptDefault: [
        {
            id: "prompt_01",
            name: "Select a Prompt",
            code: "",
            answer: "And write your own answer",
            status: 0, // 0: default, 1: add, 2: delete
        },
        {
            id: "prompt_02",
            name: "Select a Prompt",
            code: "",
            answer: "And write your own answer",
            status: 0,
        },
        {
            id: "prompt_03",
            name: "Select a Prompt",
            code: "",
            answer: "And write your own answer",
            status: 0,
        },
    ],

    lifeStyleSingle: {
        datingPurpose: {},
        languages: [],
        interests: [],
        schools: [],
        orientationSexuals: [],
        ethnicitys: []
    },

    basicInformation: {
        zodiac: "",
        familyFlan: "",
        education: "",
        personality: "",
        covidVaccine: "",
        communicationType: "",
        loveStyle: "",
    },

    styleOfLife: {
        pet: "",
        drinking: "",
        smoking: "",
        workout: "",
        dietaryPreference: "",
        socialMedia: "",
        sleepingHabit: "",
    },
};

const getters = {};

const mutations = {

    setListRecommends(state, data) {
        state.listRecommends = data
        if (data[0] === undefined) {
            state.cardFeed = [];
        } else {
            state.cardFeed = data[0];
        }
    },
    setObjectRecommends(state, val) {
        debugger
        if (val === true) {
            state.indexValue = state.indexValue + 1
            state.cardFeed = state.listRecommends[state.indexValue];
        } else {
            state.indexValue = state.indexValue - 1
            state.cardFeed = state.listRecommends[state.indexValue];
        }
    },
    setUpdateProfile(state, data) {
        state.statusUpdate = data;
    },

    setDetailInformationUser(state, data) {
        state.modelUser = data
    },

    // Profile
    // Xét Smart-photo
    setShowSmartPhoto(state, data) {
        state.modelUser.profiles.showCommon.showAge = data
    },

    /**
     * Xét about
     * @param {*} state 
     * @param {*} data 
     */
    setValueAbout(state, data) {
        state.modelUser.profiles.about = data
    },

    /**
     * Xét interest
     * @param {*} state 
     * @param {*} data 
     */
    setValueInterests(state, data) {
        let newInterestCode = [];
        let newInterestValue = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];

            newInterestCode.push(element.code);
            newInterestValue.push(element.value);
        }
        state.lifeStyleSingle.interests = newInterestValue;
        state.modelUser.profiles.interests = newInterestCode;
    },

    /**
     * Xét dating purpose
     * @param {*} state 
     * @param {*} data 
     */
    setValueDatingPurposes(state, data) {
        state.lifeStyleSingle.datingPurpose = data;
        state.modelUser.profiles.datingPurpose = data.code;
    },

    /**
     * Xét languages
     * @param {*} state 
     * @param {*} data 
     */
    setValueLanguages(state, data) {
        let newDataCode = [];
        let newDataValue = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            newDataCode.push(element.code);
            newDataValue.push(element.value);
        }

        state.modelUser.profiles.languages = newDataCode;
        state.lifeStyleSingle.languages = newDataValue;
    },

    /**
     * Xét zodiac
     * @param {*} state 
     * @param {*} value 
     */
    setValueZodiac(state, value) {
        state.basicInformation.zodiac = value.value;
        state.modelUser.profiles.zodiac = value.code;
    },

    /**
     * Xét education
     * @param {*} state 
     * @param {*} value 
     */
    setValueEducation(state, value) {
        state.basicInformation.education = value.value;
        state.modelUser.profiles.education = value.code;
    },

    /**
     * Xét personality
     * @param {*} state 
     * @param {*} value 
     */
    setValuePersonality(state, value) {
        state.basicInformation.personality = value.value;
        state.modelUser.profiles.personality = value.code;
    },

    /**
     * Xét Covid Vaccines
     * @param {*} state 
     * @param {*} value 
     */
    setValueCovidVaccines(state, value) {
        state.basicInformation.covidVaccine = value.value;
        state.modelUser.profiles.covidVaccine = value.code;
    },

    /**
     * Xét Family Plan
     * @param {*} state 
     * @param {*} value 
     */
    setValueFamilyPlan(state, value) {
        state.basicInformation.familyFlan = value.value;
        state.modelUser.profiles.familyFlan = value.code;
    },

    /**
     * Xét Communication
     * @param {*} state 
     * @param {*} value 
     */
    setValueCommunication(state, value) {
        state.basicInformation.communicationType = value.value;
        state.modelUser.profiles.communicationType = value.code;
    },

    /**
     * Xét Love style
     * @param {*} state 
     * @param {*} value 
     */
    setValueLoveStyles(state, value) {
        state.basicInformation.loveStyle = value.value;
        state.modelUser.profiles.loveStyle = value.code;
    },

    /**
     * Xét Pets
     * @param {*} state 
     * @param {*} value 
     */
    setValuePets(state, value) {
        state.styleOfLife.pet = value.value;
        state.modelUser.profiles.pet = value.code;
    },

    /**
     * Xét Drinking
     * @param {*} state 
     * @param {*} value 
     */
    setValueDrinking(state, value) {
        state.styleOfLife.drinking = value.value;
        state.modelUser.profiles.drinking = value.code;
    },

    /**
     * Xét smoking
     * @param {*} state 
     * @param {*} value 
     */
    setValueSmoking(state, value) {
        state.styleOfLife.smoking = value.value;
        state.modelUser.profiles.smoking = value.code;
    },

    /**
     * Xét workout
     * @param {*} state 
     * @param {*} value 
     */
    setValueWorkout(state, value) {
        state.styleOfLife.workout = value.value;
        state.modelUser.profiles.workout = value.code;
    },

    /**
     * xét DietaryPreference
     * @param {*} state 
     * @param {*} value 
     */
    setValueDietaryPreference(state, value) {
        state.styleOfLife.dietaryPreference = value.value;
        state.modelUser.profiles.dietaryPreference = value.code;
    },

    /**
     * Xét Social Media
     * @param {*} state 
     * @param {*} value 
     */
    setValueSocialMedia(state, value) {
        state.styleOfLife.socialMedia = value.value;
        state.modelUser.profiles.socialMedia = value.code;
    },

    /**
     * Xét Sleeping Habit
     * @param {*} state 
     * @param {*} value 
     */
    setValueSleepingHabit(state, value) {
        state.styleOfLife.sleepingHabit = value.value;
        state.modelUser.profiles.sleepingHabit = value.code;
    },

    /**
     * Xét job title
     * @param {*} state 
     * @param {*} value 
     */
    setValueJobTitle(state, value) {
        state.modelUser.profiles.jobTitle = value;
    },

    setShowHeight(state, data) {
        state.modelUser.profiles.showCommon.showHeight = data
    },

    setValueHeight(state, data) {
        state.modelUser.profiles.height = data
    },

    setValueChildrenPlan(state, data) {
        state.modelUser.profiles.childrenPlan = data
    },

    setShowChildrenPlan(state, data) {
        state.modelUser.profiles.showCommon.showChildrenPlan = data
    },

    setValueDrug(state, data) {
        state.modelUser.profiles.drug = data
    },

    setShowDrug(state, data) {
        state.modelUser.profiles.showCommon.showDrug = data
    },

    setValueEthnicity(state, data) {
        let newDataCode = [];
        let newDataValue = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            newDataCode.push(element.code);
            newDataValue.push(element.value);
        }

        state.modelUser.profiles.ethnicitys = newDataCode;
        state.lifeStyleSingle.ethnicitys = newDataValue;
    },

    setShowEthnicity(state, data) {
        state.modelUser.profiles.showCommon.showEthnicity = data
    },

    setValueSchool(state, value) {
        debugger
        state.modelUser.profiles.school = value;
    },

    setValueAddressLocation(state, data) {
        state.modelUser.profiles.address = data;
    },

    setValueGender(state, gender) {
        state.modelUser.profiles.gender = gender;
    },

    setValueSexuals(state, sexuals) {
        const index =
            state.modelUser.profiles.orientationSexuals.indexOf(sexuals);
        if (index > -1) {
            // only splice array when item is found
            state.modelUser.profiles.orientationSexuals.splice(index, 1);
            state.isCheckBox = false;
            // 2nd parameter means remove one item only
        } else {
            if (state.modelUser.profiles.orientationSexuals.length < 3) {
                state.modelUser.profiles.orientationSexuals.push(sexuals);
            } else {
                state.isCheckBox = true;
            }
        }
    },

    setValueListSexuals(state, data) {
        let newSexualCode = [];
        let newSexualValue = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];

            newSexualCode.push(element.code);
            newSexualValue.push(element.value);
        }
        state.lifeStyleSingle.orientationSexuals = newSexualValue;
        state.modelUser.profiles.orientationSexuals = newSexualCode;
    },


    setShowValueDistance(state, value) {
        state.modelUser.profiles.showCommon.showDistance = value;
    },

    setShowValueAge(state, value) {
        state.modelUser.profiles.showCommon.showAge = value;
    },
    setShowValueEducation(state, value) {
        state.modelUser.profiles.showCommon.showEducation = value;
    },
    setShowValueFamily(state, value) {
        state.modelUser.profiles.showCommon.showFamilyPlan = value;
    }



};

const actions = {
    /**
     * Lấy danh sách Recommends
     * @param {*} param0
     * @param {*} data
     * @returns
     */
    async getListRecommends({ commit }, data) {
        return new Promise((resolve, reject) => {
            http_mongo
                .get(`api/v1/cards/recommends?pageSize=${data.pageSize}&currentPage=${data.currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        commit("setListRecommends", response.data.data);
                        resolve(response.data.data);
                    } else {
                        reject("Error: API returned non-200 status");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Cập nhật thông tin 
     * @param {*} param0
     * @param {*} data
     * @returns
     */
    async updateProfileUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http_mongo
                .post(`api/v1/profile`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        commit("setUpdateProfile", response.data.data);
                        resolve(response.data.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },


    /**
     * Cập nhật setting
     * @param {*} param0
     * @param {*} data
     */
    async updateSettingUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http_mongo
                .post(`api/v1/setting`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        commit("setUpdateProfile", response.data.data);
                    }
                })
                .catch((error) => { });
        });
    },


    /**
     * Lấy chi tiết thông tin user
     * @param {*} param0 
     * @returns 
     */
    async getDetailUser({ commit }) {
        return new Promise((resolve, reject) => {
            http_mongo
                .get(`api/v1/profile`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        commit("setDetailInformationUser", response.data.data);
                        resolve(response.data.data);
                    }

                })
                .catch((error) => { reject(error); });
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
