import { createStore } from "vuex";
import LoginModule from "./login/loginModule"; // Import module
import FeedModule from "./feeds/feedModule";
import UserModule from "./users/userModule";
import CommonModule from "./common/commonModule";
import MessageModule from "./message/messageModule";
import SignModule from "./login/sign-module";
import ProfileModule from "./users/profileModule";

export default createStore({
  modules: {
    loginModule: LoginModule,
    feedModule: FeedModule,
    userModule: UserModule,
    commonModule: CommonModule,
    messageModule: MessageModule,
    signModule: SignModule,
    profileModule: ProfileModule,
  },
});
