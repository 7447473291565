<template>
  <div class="w-full h-full">
    <!-- Header -->

    <!-- Body -->
    <div class="w-full h-full">
      <!-- Page 1 -->

      <section class="main-banner">
        <video
          ref="myVideo"
          class="w-full relative"
          autoplay
          muted
          :controls="false"
          @ended="restartVideo()"
        >
          <source
            src="../../assets/background/production_id_5107299_(2160p).mp4"
            type="video/mp4"
          />
        </video>

        <div class="video-overlay header-text">
          <div class="caption">
            <h4>we Believe in True Love</h4>
            <h2>& <em>we want to help you find it</em></h2>
          </div>
        </div>
      </section>

      <section class="section" id="call-to-action">
        <div class="container mx-auto">
          <div
            class="mx-auto lg:flex lg:justify-between px-2 max-w-7xl md:px-8"
          >
            <div>
              <div
                class="text-left md:w-[600px] w-[440px] border rounded-lg shadow-lg p-6"
              >
                <div class="font-semibold text-4xl">
                  Same merge. Chat. Meloha®.
                </div>
                <div class="mt-10">
                  Go beyond everyday acquaintances and connect with everyone
                  people far and near everywhere. You are about to experience
                  online dating The hottest route — all you need is a few panels
                  Great photos and a clear bio to stand out.
                </div>

                <div class="main-button scroll-to-section mt-10">
                  <button
                    type="button"
                    class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2"
                  >
                    Join now
                  </button>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center p-5">
              <img
                src="../../assets/background/home1.png"
                width="350"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Page 2 -->

      <section class="section bg-footer" id="call-to-action">
        <div class="container mx-auto">
          <div class="mx-auto px-2 max-w-7xl md:px-8">
            <div
              class="text-center sm:mx-auto sm:w-10/12 lg:mr-auto lg:mt-0 lg:w-4/5"
            >
              <div class="cta-content">
                <h2 class="text-xl">why us?</h2>
                <p class="text-class">
                  Welcome to a world of endless possibilities, where
                  <b class="text-blue-500">FINDING LOVE</b> is made
                  <span class="text-blue-400">effortless and exciting</span>.
                  Our dating app is designed with one mission in mind: to help
                  you discover
                  <span class="text-blue-400">meaningful connections </span>
                  that stand the test of time.
                </p>
              </div>
            </div>
            <div class="text-center sm:mx-auto lg:mr-auto lg:mt-0">
              <Carousel v-bind="settings" :breakpoints="breakpoints">
                <Slide v-for="item in listImage" :key="item">
                  <div
                    class="image-home"
                    :style="`background-image: url('${item.url}');`"
                  ></div>
                </Slide>

                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <!-- Page 3 -->

      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import FooterPage from "@/layout/footer/footer-page.vue";

import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default defineComponent({
  components: {
    FooterPage,
    Carousel,
    Slide,
    Navigation,

    // BhButtonV1,
  },
  name: "main-menu",

  data() {
    return {
      nameCreateAccount: "Create Account",
      nameLearnMore: "Learn More",

      stepPhone: false,
      nameButtonDefault: "Create Account",

      urlImage: require("@/assets/icon_svg/ic_outline-plus.svg"),
      colorWhite: "color-w",
      codeText: "#000000",

      listImage: [
        {
          id: "01",
          url: require("@/assets/background/1.png"),
        },
        {
          id: "02",
          url: require("@/assets/background/2.png"),
        },
        {
          id: "03",
          url: require("@/assets/background/3.png"),
        },
        {
          id: "04",
          url: require("@/assets/background/4.png"),
        },
        {
          id: "05",
          url: require("@/assets/background/5.png"),
        },
        {
          id: "06",
          url: require("@/assets/background/6.png"),
        },
        {
          id: "07",
          url: require("@/assets/background/7.png"),
        },
        {
          id: "08",
          url: require("@/assets/background/8.png"),
        },
      ],

      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },

  methods: {
    // Login google

    restartVideo() {
      // Lấy tham chiếu đến đối tượng video
      const video = this.$refs.myVideo;

      // Đặt currentTime về 0 để quay lại đầu video
      video.currentTime = 0;

      // Bắt đầu phát lại video
      video.play();
    },
  },
});
</script>

<style lang="scss" scoped>
.text-small-title {
  font-size: 24px;
  color: #4f5b6d;
}
.text-title {
  font-size: 36px;
  font-weight: 600;
}
.text-big-title {
  font-size: 64px;
  font-weight: 600;
}

.dialog-login {
  top: 10 0px;
  width: 400px;
  height: 550px;
  background-color: #111418;
  color: #e5e7ea;
  border-radius: 10px;
}

.action-btn {
  border: 1px solid;
  border-radius: 25px;
  text-align: center;
  padding: 6px;
}

.w-slider {
  width: 55rem;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Màu đen với độ mờ là 0.5 */
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  z-index: 2; /* Đảm bảo overlay nằm trên cùng */
}

.main-banner {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-bottom: -7px;
}
#bg-video {
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.main-banner .caption {
  text-align: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .main-banner .caption h4 {
    font-size: 20px;
  }

  .main-banner .caption h2 {
    font-size: 44px;
  }
}

@media (min-width: 1024px) {
  .main-banner .caption h4 {
    font-size: 30px;
  }

  .main-banner .caption h2 {
    font-size: 64px;
  }
}

@media (min-width: 1280px) {
  .main-banner .caption h4 {
    font-size: 30px;
  }

  .main-banner .caption h2 {
    font-size: 64px;
  }
}

@media (min-width: 1536px) {
  .main-banner .caption h4 {
    font-size: 30px;
  }

  .main-banner .caption h2 {
    font-size: 64px;
  }
}
.main-banner .caption h4 {
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}
.main-banner .caption h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 em {
  font-style: normal;
  color: #f5a425;
  font-weight: 900;
}

.image-home {
  width: 293px;
  height: 600px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.5s ease;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vp-c-green);
  color: #fff;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .text-class {
    font-size: 18px;
  }
}
</style>
