import { http_mongo } from "../../configs/http-mongo";

const state = {
  tokenUser: {},
  tokenId: "",

  profileRegister: {
    oAuth2Id: "",
    fullname: "",
    username: "",
    email: "",
    phone: "",
    dob: "",
    profiles: {
      avatars: [],
      gender: "",
      about: "",
      datingPurpose: "",
      languages: [],
      zodiac: "",
      education: "",
      school: "",
      company: "",
      familyFlan: "",
      covidVaccine: "",
      personality: "",
      communicationType: "",
      loveStyle: "",
      pet: "",
      drinking: "",
      smoking: "",
      workout: "",
      dietaryPreference: "",
      socialMedia: "",
      sleepingHabit: "",
      favoriteSongs: [],
      jobTitle: "",
      showGender: false,
      orientationSexuals: [],
      showSexual: false,
      interests: [],
      address: "",
      showAge: false,
      showDistance: false,
    },
    settings: {
      distancePreference: {
        range: 10,
        unit: "km",
        onlyShowInThis: false,
      },
      genderFilter: "",
      agePreference: {
        min: 20,
        max: 30,
        onlyShowInThis: false,
      },
      showSameOrientationSexual: false,
      showOnlineStatus: false,
      showActiveStatus: false,
      autoPlayVideo: "always",
      notiSeenMsg: false,
      showMePersonLikeMe: false,
      global: {
        isEnabled: false,
        languages: [],
      },
      incognitoMode: false,
      toppicksDiscoverable: true,
    },
    verifyStatus: false,
    onlineNow: false,
    activeStatus: "",
    location: {},
    coins: 0,
    numberBooster: 0,
    numberSuperLike: 0,
    numberNotiSeenMsg: 0,
  },

  informationChose: {
    fullname: "",
    email: "",
    username: "",
    dob: "",
    avatars: [],
    gender: "",
    showGender: false,
    orientationSexuals: [],
    showSexual: false,
    location: {},
    interests: [],
  },

  isActiveId: false,
  avatarChecked: [],
  interestChecks: [],
  sexualChecks: [],

  showLogin: localStorage.getItem("showLogin"),

  outputRegister: {},

  deleteAccount: "",
  informationUserLogin: {},
};

const getters = {};

const mutations = {
  setPropertyUser(state, data) {
    state.tokenUser = data;
    state.informationUserLogin = data.user;
    this.state.userModule.detailProfile = data.user;
    if (data !== "") {
      state.tokenId = data?.token;
      localStorage.setItem("tokenIdV2", data.token);
      localStorage.setItem("idUser", data.user._id);
      localStorage.setItem("unit", data.user.settings.distancePreference.unit);
    } else {
      state.tokenUser = data;
    }
  },

  setRegisterUser(state, data) {
    state.outputRegister.tokenIdV2 = data.token;
    state.outputRegister.user = data.user;
  },

  /**
   * Xét giá trị email
   * @param {*} state
   * @param {*} data
   */
  setEmailRegister(state, data) {
    localStorage.setItem("emailIdV2", data);
    state.showLogin = false;
    state.informationChose.email = data;
  },

  setShowLogin(state, data) {
    state.showLogin = data;
  },
  /**
   * Xét giá trị first name
   * @param {*} state
   * @param {*} firstName
   */
  setFirstNameRegister(state, firstName) {
    state.informationChose.fullname = firstName;
  },

  /**
   *
   * @param {*} state
   * @param {*} birthday
   */
  setBirthdayRegister(state, birthday) {
    state.informationChose.dob = birthday;
  },

  /**
   * Xét giá trị sexual
   * @param {*} state
   * @param {*} data
   */
  setSexualRegister(state, data) {
    if (data.length === 0) {
      state.informationChose.orientationSexuals = [];
      state.sexualChecks = [];
    } else {
      const index = state.informationChose.orientationSexuals.indexOf(
        data.code
      );
      if (index > -1) {
        // only splice array when item is found
        state.informationChose.orientationSexuals.splice(index, 1);
        state.sexualChecks.splice(index, 1);
        // 2nd parameter means remove one item only
      } else {
        if (state.informationChose.orientationSexuals.length < 3) {
          state.informationChose.orientationSexuals.push(data.code);
          state.sexualChecks.push(data);
        }
      }
    }
  },

  /**
   * Xét giá trị gender
   * @param {*} state
   * @param {*} data
   */
  setGenderIntermediate(state, data) {
    state.informationChose.gender = data;
  },

  /**
   * Giá trị để thêm mới
   * @param {*} state
   * @param {*} data
   */
  setGenderRegister(state, data) {
    state.profileRegister.profiles.gender = data;
  },

  setShowGenderRegister(state, data) {
    state.profileRegister.profiles.showGender = data;
  },

  setShowMeGender(state, data) {
    state.informationChose.showGender = data;
  },

  /**
   * Xét giá trị Interests
   * @param {*} state
   * @param {*} data
   */
  setInterestIntermediate(state, data) {
    if (data.length === 0) {
      state.informationChose.interests = [];
      state.interestChecks = [];
    } else {
      const index = state.informationChose.interests.indexOf(data.code);
      if (index > -1) {
        // only splice array when item is found
        state.informationChose.interests.splice(index, 1);
        state.interestChecks.splice(index, 1);
        // 2nd parameter means remove one item only
        state.isActiveId = false;
      } else {
        if (state.informationChose.interests.length < 5) {
          state.informationChose.interests.push(data.code);
          state.interestChecks.push(data);

          state.isActiveId = true;
        } else {
          state.isActiveId = false;
        }
      }
    }
  },

  setPhotoRegister(state, photos) {
    const idUrl = photos.id;
    const index = state.avatarChecked.findIndex((x) => x.id === idUrl);
    if (index !== -1) {
      if (state.avatarChecked.length === 1) {
        // only splice array when item is found
        state.avatarChecked.splice(index, 1); // 2nd parameter means remove one item only
        state.informationChose.avatars.splice(index, 1);
      } else {
        state.avatarChecked.splice(index, 1); // 2nd parameter means remove one item only
        state.informationChose.avatars.splice(index, 1);
      }
    } else {
      state.avatarChecked.push(photos);
      state.informationChose.avatars.push(photos.url);
    }
    state.informationChose.avatars;
  },

  setInterestsRegister(state, data) {
    state.profileRegister.profiles.interests = data;
  },

  setLocationRegister(state, location) {
    state.informationChose.location = {
      lat: location.latitude,
      long: location.longitude,
    };

    localStorage.setItem("latitude", location.latitude);
    localStorage.setItem("longitude", location.longitude);
  },

  setShowSexualRegister(state, value) {
    state.informationChose.showSexual = value;
  },

  setDeleteAccountUser(state, value) {
    state.deleteAccount = value;
  },
};

const actions = {
  /**
   * Login app by oAuthId
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async loginApp({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .post(`api/v1/login`, data)
        .then((response) => {
          if (response.status === 200) {
            commit("setPropertyUser", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Đăng ký app
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async registerUserByOAthId({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .post(`api/v1/register`, data)
        .then((response) => {
          if (response.status === 200) {
            commit("setRegisterUser", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteAccountUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .delete(
          `/api/v1/users/delete`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          },
          data
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setDeleteAccountUser", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
