import { http_mongo } from "../../configs/http-mongo";

const state = {
  isHeader: true,
  profileRegister: {
    oAuth2Id: "",
    fullname: "",
    dob: "",

    location: {
      lat: "",
      long: "",
    },
    profiles: {
      avatars: [],
      prompts: [],
      interests: [],
      orientationSexuals: [],
      showCommon: {
        showSexual: false,
        showGender: false,
        showHeight: false,
        showEthnicity: false,
        showChildrenPlan: false,
        showFamilyPlan: false,
        showWork: false,
        showSchool: false,
        showEducation: false,
        showDrinking: false,
        showSmoking: false,
        showDrug: false,
      },
      about: "",
      gender: "",
      height: 0,
      school: "",
      company: "",
      jobTitle: "",
      address: "",
      childrenPlan: "",
      education: "",
      familyPlan: "",
      pet: "",
      drinking: "",
      smoking: "",
      ethnicitys: [],
      drug: "",
    },

    settings: {
      genderFilter: "",
    },
  },

  registerChose: {
    interests: [],
    educations: [],
    ethnicities: [],
    childrens: [],
    familyPlans: [],
    drinkings: [],
    smokings: [],
    drugs: [],
    orientationSexuals: [],
  },

  listPrompt: [
    {
      id: "prompt_01",
      name: "Select a Prompt",
      code: "",
      answer: "And write your own answer",
      status: 0, // 0: default, 1: add, 2: delete
    },
    {
      id: "prompt_02",
      name: "Select a Prompt",
      code: "",
      answer: "And write your own answer",
      status: 0,
    },
    {
      id: "prompt_03",
      name: "Select a Prompt",
      code: "",
      answer: "And write your own answer",
      status: 0,
    },
  ],

  interestChecks: [],
  sexualChecks: [],
  educationChecks: [],
  childrenChecks: [],
  familyPlanChecks: [],
  drinkingsChecks: [],
  smokeChecks: [],
  ethnicityChecks: [],
  drugChecks: [],
  itemChose: {},
  isActiveId: false,
  avatarChecked: [],
};

const getters = {};

const mutations = {
  setHideHeaderAbout(state, data) {
    state.isHeader = data;
  },
  /**
   *  Xét fullName
   * @param {*} state
   * @param {*} fullName
   */
  setFullNameRegister(state, fullName) {
    state.profileRegister.fullname = fullName;
  },
  /**0.
   * .0.
   * 0
   * Xét birthday
   * @param {*} state
   * @param {*} birthday
   */
  setBirthdayRegister(state, birthday) {
    state.profileRegister.dob = birthday;
  },

  /**
   * Xét height
   * @param {*} state
   * @param {*} height
   */
  setHeightRegister(state, height) {
    state.profileRegister.profiles.height = height;
  },
  /**
   * Xét show Height
   * @param {*} state
   * @param {*} status
   */
  setHeightShowMe(state, status) {
    state.profileRegister.profiles.showCommon.showHeight = status;
  },
  /**
   * Xét company
   * @param {*} state
   * @param {*} company
   */
  setCompanyRegister(state, company) {
    state.profileRegister.profiles.company = company;
  },
  /**
   * Xét school
   * @param {*} state
   * @param {*} school
   */
  setSchoolRegister(state, school) {
    state.profileRegister.profiles.school = school;
  },
  /**
   * Xét gender
   * @param {*} state
   * @param {*} keyGender
   */
  setGenderRegister(state, keyGender) {
    state.profileRegister.profiles.gender = keyGender;
  },
  /**
   * Xét show Gender
   * @param {*} state
   * @param {*} status
   */
  setGenderShowMe(state, status) {
    state.profileRegister.profiles.showCommon.showGender = status;
  },
  /**
   * Xét gender filter
   * @param {*} state
   * @param {*} keyGenderFilter
   */
  setGenderFilterRegister(state, keyGenderFilter) {
    state.profileRegister.settings.genderFilter = keyGenderFilter;
  },

  setChooseSexualState(state, data) {
    if (data.length === 0) {
      state.registerChose.orientationSexuals = [];
      state.sexualChecks = [];
    } else {
      const index = state.registerChose.orientationSexuals.indexOf(data.code);
      if (index > -1) {
        // only splice array when item is found
        state.registerChose.orientationSexuals.splice(index, 1);
        state.sexualChecks.splice(index, 1);
        // 2nd parameter means remove one item only
      } else {
        if (state.registerChose.orientationSexuals.length < 3) {
          state.registerChose.orientationSexuals.push(data.code);
          state.sexualChecks.push(data);
        }
      }
    }
  },

  setSexualShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showSexual = value;
  },

  setSexualRegister(state, keySexual) {
    state.profileRegister.profiles.orientationSexuals = keySexual;
  },

  setChooseInterestState(state, data) {
    if (data.length === 0) {
      state.registerChose.interests = [];
      state.interestChecks = [];
    } else {
      const index = state.registerChose.interests.indexOf(data.code);
      if (index > -1) {
        // only splice array when item is found
        state.registerChose.interests.splice(index, 1);
        state.interestChecks.splice(index, 1);
        // 2nd parameter means remove one item only
        state.isActiveId = false;
      } else {
        if (state.registerChose.interests.length < 5) {
          state.registerChose.interests.push(data.code);
          state.interestChecks.push(data);

          state.isActiveId = true;
        } else {
          state.isActiveId = false;
        }
      }
    }
  },

  setInterestRegister(state, data) {
    state.profileRegister.profiles.interests = data;
  },

  /**
   * Education
   * @param {*} state
   * @param {*} data
   */

  setChooseEducationState(state, data) {
    if (data.length !== 0) {
      state.educationChecks = [data];
      state.registerChose.educations = [data];
      state.profileRegister.profiles.education = data.code;
    }
  },

  setEducationRegister(state, data) {
    state.profileRegister.profiles.education = data.code;
  },

  setEducationShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showEducation = value;
  },

  setChooseEthnicityState(state, data) {
    if (data.length === 0) {
      state.registerChose.ethnicities = [];
      state.ethnicityChecks = [];
    } else {
      const index = state.registerChose.ethnicities.indexOf(data.code);
      if (index > -1) {
        // only splice array when item is found
        state.registerChose.ethnicities.splice(index, 1);
        state.ethnicityChecks.splice(index, 1);
        // 2nd parameter means remove one item only
      } else {
        if (state.registerChose.ethnicities.length < 3) {
          state.registerChose.ethnicities.push(data.code);
          state.ethnicityChecks.push(data);
        }
      }
    }
  },
  setEthnicityRegister(state, keyEthnicity) {
    state.profileRegister.profiles.ethnicitys = keyEthnicity;
  },
  setEthnicityShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showEthnicity = value;
  },

  setChooseChildrenState(state, data) {
    if (data.length !== 0) {
      state.childrenChecks = [data];
      state.registerChose.childrens = [data];
      state.profileRegister.profiles.childrenPlan = data.code;
    }
  },

  setChildrenShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showChildrenPlan = value;
  },

  setChooseFamilyState(state, data) {
    if (data.length !== 0) {
      state.familyPlanChecks = [data];
      state.registerChose.familyPlans = [data];
      state.profileRegister.profiles.familyPlan = data.code;
    }
  },

  setFamilyShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showFamilyPlan = value;
  },

  setChooseDrinkState(state, data) {
    if (data.length !== 0) {
      state.drinkingsChecks = [data];
      state.registerChose.drinkings = [data];
      state.profileRegister.profiles.drinking = data.code;
    }
  },

  setDrinkShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showDrinking = value;
  },

  setChooseSmokeState(state, data) {
    if (data.length !== 0) {
      state.smokeChecks = [data];
      state.registerChose.smokings = [data];
      state.profileRegister.profiles.smoking = data.code;
    }
  },

  setSmokeShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showSmoking = value;
  },

  setChooseDrugState(state, data) {
    if (data.length !== 0) {
      state.drugChecks = [data];
      state.registerChose.drugs = [data];
      state.profileRegister.profiles.drug = data.code;
    }
  },

  setDrugShowMe(state, value) {
    state.profileRegister.profiles.showCommon.showDrug = value;
  },
  setObjectPrompt(state, data) {
    const findIndex = state.listPrompt.findIndex((x) => x.id === data.id);
    (state.listPrompt[findIndex].name = data.name),
      (state.listPrompt[findIndex].code = data.code),
      (state.listPrompt[findIndex].answer = data.valueAnswerPrompt),
      (state.listPrompt[findIndex].status = data.status);

    if (data.status === 0) {
      const index = state.profileRegister.profiles.prompts.findIndex(
        (x) => x.codePrompt === data.code
      );
      state.profileRegister.profiles.prompts.splice(index, 1);
    } else {
      state.profileRegister.profiles.prompts.push({
        codePrompt: data.code,
        answer: data.valueAnswerPrompt,
      });
    }
  },

  setPhotoImages(state, photos) {
    debugger;
    const idUrl = photos.id;
    if (state.avatarChecked.length === 0) {
      state.avatarChecked.push(photos);
      state.profileRegister.profiles.avatars.push({
        meta: {
          url: photos.url,
          thumbnails: [photos.url],
        },
      });
    } else {
      const index = state.avatarChecked.findIndex((x) => x.id === idUrl);
      if (index !== -1) {
        if (state.avatarChecked.length === 1) {
          // only splice array when item is found
          state.avatarChecked.splice(index, 1); // 2nd parameter means remove one item only
          state.profileRegister.profiles.avatars.splice(index, 1);
        } else {
          state.avatarChecked.splice(index, 1); // 2nd parameter means remove one item only
          state.profileRegister.profiles.avatars.splice(index, 1);
        }
      } else {
        state.avatarChecked.push(photos);
        state.profileRegister.profiles.avatars.push({
          meta: {
            url: photos.url,
            thumbnails: [photos.url],
          },
        });
      }
    }
  },

  setLocation(state, location) {
    state.profileRegister.location = {
      lat: location.latitude,
      long: location.longitude,
    };

    localStorage.setItem("latitude", location.latitude);
    localStorage.setItem("longitude", location.longitude);
  },
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
