<template>
  <div
    class="text-gray-100 py-3.5 px-5 shadow md:flex justify-between items-center h-header-app"
  >
    <div class="flex items-center cursor-pointer h-full">
      <img
        @click="onLoadApp()"
        src="../../assets/icon_svg/app_icon.svg"
        width="140"
        alt=""
        class="md:mr-2"
      />

      <ul
        class="md:flex md:items-center md:px-0 md:pb-0 md:static hidden md:w-auto w-full menu-mobile duration-700 ease-in"
      >
        <li
          class="md:mx-1 font-medium text-lg md:my-0 lg:my-6 lg:ml-4 md:ml-0 cursor-pointer"
          v-for="(item, index) in linkHeaders"
          :key="index"
          @click="selectRouterLink(index)"
        >
          <div
            class="text-base font-semibold hover:text-blue-400 cursor-pointer"
            :class="{ 'text-blue-400': selectedActive === index }"
          >
            {{ toUpperString(item.name) }}
          </div>
        </li>
      </ul>

      <!-- <div class="md:flex pt-2" :class="[open ? 'block' : 'hidden']">
        <div class="flex items-center">
          <img
            class="mr-1"
            src="../../assets/icon_svg/ic_language.svg"
            width="20"
            alt=""
          />
          <div>Language</div>
        </div>
      </div> -->
    </div>

    <div
      class="absolute md:hidden right-6 top-7 cursor-pointer"
      @click="menuOpen()"
    >
      <img v-if="open" src="../../assets/icon_svg/ic_cancel.svg" width="15" />

      <img
        v-else
        src="../../assets/icon_svg/ic_bi_filter_left.svg"
        width="25"
      />
    </div>

    <ul
      class="z-20 md:static absolute h-[calc(100vh-71px)] md:hidden pt-2 pb-2 w-full menu-mobile duration-700 ease-in bg-mobile"
      :class="[open ? 'block' : 'hidden']"
    >
      <li
        class="md:mx-1 text-left font-medium text-lg md:my-0 pl-5 pr-5 pt-3 pb-3 mb-1 bg-text-mobile lg:my-6 lg:ml-4 md:ml-0 cursor-pointer"
        v-for="(item, index) in linkHeaders"
        :key="index"
        @click="selectRouterLink(index)"
      >
        <div
          class="text-base font-semibold hover:text-blue-400 cursor-pointer"
          :class="{ 'text-blue-400': selectedActive === index }"
        >
          {{ toUpperString(item.name) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import funValidation from "@/middleware/validation";
// import BhButtonV1 from "@/control-ui/button/bh-button-v1.vue";

export default defineComponent({
  components: {
    // BhButtonV1,
  },
  name: "head-title",

  setup() {
    let open = ref(false);

    function menuOpen() {
      open.value = !open.value;
    }

    return { open, menuOpen };
  },
  data() {
    return {
      nameLogIn: "Log in",
      numberSize: 20,
      isShowDialog: false,
      colorRed: "#ffffff",
      isShowCreate: true,
      isShowPhone: false,
      isShowCode: false,
      phoneCode: "",
      sentCodeId: "",
      linkHeaders: [
        { name: "About" },

        {
          name: "Policy",
          active: false,
        },
        {
          name: "Term of service",
          active: false,
        },
        // {
        //   name: "PACKAGE",
        //   active: false,
        // },
      ],

      isShowEmail: false,

      codeBg: "#ffffff",
      codeText: "#000000",
      valueIntl: {},
      selectedActive: null,
    };
  },

  computed: {
    localStorageValue() {
      return this.$store.state.loginModule.showLogin;
    },

    isShowActive() {
      return this.links;
    },

    renderListLanguages() {
      return this.$store.state.commonModule.listLanguages;
    },
  },

  mounted() {
    this.selectRouterLink(-1);
  },

  methods: {
    toUpperString(value) {
      return funValidation.capitalizeFirstLetter(value);
    },
    onChangeLogin(value) {
      this.isShowDialog = value;
    },

    selectRouterLink(val) {
      this.selectedActive = val;

      if (val === 0) {
        localStorage.setItem("valueRouter", "about-view");
        this.$router.push({ path: `/about` });
      }
      if (val === 1) {
        localStorage.setItem("valueRouter", "policy-view");
        this.$router.push({ path: `/policy` });
      }
      if (val === 2) {
        localStorage.setItem("valueRouter", "term-of-service-view");
        this.$router.push({ path: `/term-of-service` });
      }
      if (val === -1) {
        localStorage.setItem("valueRouter", "introduction-view");
        this.$router.push({ path: `/` });
      }

      // if (val === 4) {
      //   localStorage.setItem("valueRouter", "package-view");
      //   this.$router.push({ path: `/package` });
      // }
    },
    activateLink(link) {
      // Đặt trạng thái active cho mục tiêu vừa nhấp
      this.links.forEach((item) => {
        item.active = false;
      });
      link.active = true;
      if (link.name === "download") {
        const linkCHPlay =
          "https://play.google.com/store/games?device=windows&utm_source=apac_med&hl=vi-VN&utm_medium=hasem&utm_content=Aug0922&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-vn-1003227-med-hasem-py-Evergreen-Aug0922-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700055535927669_creativeid_446323003829_device_c&gclid=Cj0KCQiAo7KqBhDhARIsAKhZ4ugQLyJQA0yg1CJn-0prnQHFX0Xi_tU3z36IgTd0xaKn-SoQ-kllfAIaArU2EALw_wcB&gclsrc=aw.ds";
        window.location.href = linkCHPlay;
      } else {
        localStorage.setItem("valueRouter", link.name + "-view");
        this.$router.push({ path: `/${link.name}` });
      }
    },

    onLoadApp() {
      this.$emit("onViewLoadApp", true);
      this.selectedActive = -1;
      localStorage.setItem("valueRouter", "introduction-view");
      this.$router.push({ path: "/" }).catch(() => {});
    },
  },
});
</script>

<style lang="scss" scoped>
.menu-mobile {
  top: 4.3rem;
  right: 0;
}

.active {
  color: #16d116; /* Màu văn bản khi active */
  /* Thêm các thuộc tính CSS khác theo ý muốn */
}
.bg-mobile {
  background-color: #28456b;
}
.bg-text-mobile {
  background-color: #4a5563;
}
/* CSS cho hover (nếu cần) */
</style>
