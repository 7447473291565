import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/style/tailwind.css";
import "./assets/style/global.css";

import store from "./stores/data";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import "vue3-carousel/dist/carousel.css";
import "vue3-carousel-3d/dist/index.css";

import Carousel3d from "vue3-carousel-3d";

// Countries
import "intl-tel-input/build/css/intlTelInput.css";
import SplitCarousel from "vue-split-carousel";
import i18n from "./middleware/i18n.js";

//
import registerServiceWorker from "./configs/worker/registerServiceWorker.js";
//

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

const app = createApp(App);
registerServiceWorker();

// app.config.globalProperties.$axios = axiosInstance;
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(SplitCarousel);
app.use(Carousel3d);
app.use(i18n);
app.use(VueCropper);

app.mount("#app");
