/* eslint-disable prettier/prettier */
import { http_mongo } from "../../configs/http-mongo";
import { http_admin } from "../../configs/http-admin";

const state = {
  statusBack: false,
  objectNope: {},
  objectLike: {},

  resultNope: {},

  listImageThumbnail: [],
};

const getters = {};

const mutations = {
  setPostNopeUser(state, data) {
    state.resultNope = data;
  },
  setPostBackUser(state, data) {
    state.statusBack = data;
  },
  setSaveObjectNope(state, data) {
    state.objectNope = data;
  },
  setSaveObjectBack(state, data) {
    this.state.profileModule.cardFeed = state.objectNope;
  },
  setPostLikeUser(state, data) {
    state.objectLike = data;
  },

  setPostImageThumbnail(state, data) {
    state.listImageThumbnail = data;
  },
};

const actions = {
  /**
   * Chức năng nope
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async postNopeUser({ commit }, data) {
    await http_mongo
      .post(`/api/v1/nope`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("setPostNopeUser", response.data.data);
        }
      })
      .catch((error) => {});
  },

  async postLikeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .post(`/api/v1/like`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setPostLikeUser", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Chức năng back
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async postBackUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .post(`/api/v1/back`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setPostBackUser", response.status);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Chức năng supper like
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async postSupperLikeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .post(`/api/v1/superLike`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setPostSupperLikeUser", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Lấy danh sách topic
   * @param {*} param0
   * @param {*} data
   */
  async getTopicExplore({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .get(`api/v1/topics`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setTopicExplore", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Lấy danh sách card topic
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async getTopicCard({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .get(
          `api/v1/cards/group/${data.idTopic}?pageSize=${data.pageSize}&currentPage=${data.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setTopicCard", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Lấy danh sách những người like bạn
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  async getListOtherUsersLikeYou({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .get(
          `api/v1/list-action-you?pageSize=${data.pageSize}&currentPage=${data.currentPage}&action=${data.action}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setListOtherUsersLikeYou", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getListYouLikeOtherUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      http_mongo
        .get(
          `api/v1/list-you-action?pageSize=${data.pageSize}&currentPage=${data.currentPage}&action=${data.action}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("tokenIdV2")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setListYouLikeOtherUsers", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async postImageThumbnail({ commit }, data) {
    debugger;
    return new Promise((resolve, reject) => {
      http_admin
        .post(
          `api/upload-thumbnail?pageSize=${data.pageSize}&currentPage=${data.currentPage}`,
          { listImage: data.listImage },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin-token")}`,
            },
          }
        )
        .then((response) => {
          debugger;
          if (response.status === 200) {
            commit("setPostImageThumbnail", response.data.data);
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getAccountUser({ commit }, data) {
    debugger;
    return new Promise((resolve, reject) => {
      http_admin
        .get(`api/users`, {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIwMUhNNUtON05ZM1JSWDVDRDI1Ujc5NkFZVCIsImVtYWlsIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiIsImV4cCI6MTcxMzgzNjEyMCwiaWF0IjoxNzEzMjMxMzIwfQ.AFyN70YnCHyr_jzIW-SH_TqADQOuO3jfZLci3IalKoo`,
          },
        })
        .then((response) => {
          debugger;
          if (response.status === 200) {
            resolve(response.data.data);
          } else {
            reject("Error: API returned non-200 status");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
